<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-2">
                    <label
                        :class="{'text-danger': states.sort === false}"
                        class="form-label"
                        for="sort"
                    >{{ $t('partners::lead_sources.columns.sort').ucFirst() }}</label
                    >
                    <input
                        id="sort"
                        v-model="model.sort"
                        :class="{'border-danger': states.sort === false}"
                        :placeholder="$t('partners::lead_sources.placeholders.sort').ucFirst()"
                        class="form-control"
                        type="number"
                    />
                    <div :class="{'d-block': states.sort === false}" class="invalid-feedback">{{ errors.sort }}</div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.code === false}"
                        class="form-label"
                        for="code"
                    >{{ $t('partners::lead_sources.columns.code').ucFirst() }}</label
                    >
                    <input
                        id="code"
                        v-model="model.code"
                        :class="{'border-danger': states.code === false}"
                        :placeholder="$t('partners::lead_sources.placeholders.code').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.code === false}" class="invalid-feedback">{{ errors.code }}</div>
                </div>
                <div class="col-lg-7">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('partners::lead_sources.columns.name').ucFirst() }} *</label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('partners::lead_sources.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "LeadSourceForm",

    emits: ['saved'],

    props: {
        leadSource: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'leadSource/loading',
            'states': 'leadSource/states',
            'errors': 'leadSource/errors'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: JSON.parse(JSON.stringify(this.leadSource)),
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`leadSource/${method}`, this.model).then(saved => {
                this.model = JSON.parse(JSON.stringify(saved))
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.' + '/master-data/lead-sources/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },
    },

    watch: {
        leadSource: function () {
            this.model = JSON.parse(JSON.stringify(this.leadSource))
        },
    },

    created() {
        // console.log(this.leadSource)
    }
}
</script>

<style scoped>

</style>
